/**
 * Extracts and processes encounter data from the given encounter bundle.
 * @param {Object} encounterBundle - The bundle containing encounter resources.
 * @returns {Array} - Processed encounter data.
 */
export const getPrimeEncounter = (encounterBundle) => {

  const encounterResource = getEncounterResources(encounterBundle, 'Encounter');
  const patientResource = getEncounterResources(encounterBundle, 'Patient');
  const messageHeaderResources = getEncounterResources(encounterBundle, 'MessageHeader');
  const locationResource = getEncounterResources(encounterBundle, 'Location');
  const conditionResource = getEncounterResources(encounterBundle, 'Condition');

  const primeAdtData = encounterResource?.map((encounter) => {
    const encounterHeaderResource = messageHeaderResources.find((header) => header.id === encounter.id);
    const encounterLocationId = encounter?.location[0]?.location?.reference?.split('/')[1];
    const encounterLocationResource = locationResource.find((location) => location.id === encounterLocationId);
    const encounterConditionIds = encounter?.reasonReference?.map((condition) => condition.reference.split('/')[1]);
    const encounterConditionResource = conditionResource.filter((condition) => encounterConditionIds.includes(condition.id));
    const encounterPatientId = encounter?.subject?.reference?.split('/')[1];
    const encounterPatientResource = patientResource.find((patient) => patient.id === encounterPatientId);
    const messageTriggerEventType = encounterHeaderResource.extension?.find((e) => e.url.includes('/source-event-trigger')).valueCodeableConcept?.coding[0]?.code;
    const dischargeDisposition = encounter?.hospitalization?.dischargeDisposition?.coding[0]?.code;
    const crossWalkDischargeDisposition = getExtensionValue(encounterLocationResource, '/discharge-disposition');
    const locationName = getExtensionValue(encounterLocationResource, '/adt-cross-walk-location-name');
    const encounterLocationNpi = getExtensionValue(encounterLocationResource, '/location-npi');
    const facilityName = getExtensionValue(encounterLocationResource, '/patient-location-facility-name');
    const facilityLocation = getExtensionValue(encounterLocationResource, '/patient-location-facility-namespace-id');
    const facilityLocationId = getExtensionValue(encounterLocationResource, '/patient-location-facility-universal-id');
    const messageControlId = getExtensionValue(encounterHeaderResource, '/source-record-id');
    const messageAdmissionType = getExtensionValue(encounterHeaderResource, '/encounter-admission-type');

    const encounterDiagnosisCodes = getEncounterDiagnosisCodes(encounterConditionResource);

    return {
      correlationId: encounter.id,
      eventDate: getEncounterEventDate(encounterHeaderResource),
      admissionType: messageAdmissionType,
      eventTitle: getEncounterEventTitle(messageTriggerEventType, locationName, facilityName, facilityLocation, facilityLocationId),
      patientId: getEncounterPatientId(encounterPatientResource),
      visitNumber: encounter?.identifier?.find((e) => e.type?.coding[0]?.code === 'VN')?.value ?? '',
      location: getEncounterLocation(encounterLocationResource),
      pointOfCare: getExtensionValue(encounterLocationResource, '/patient-point-of-care') ?? '',
      room: encounterLocationResource?.type?.find((e) => e.coding?.[0]?.code === 'room')?.coding?.[0]?.display ?? '',
      patientClass: getExtensionValue(encounterHeaderResource, '/patient-class') ?? '',
      attendingDoctor: getEncounterPractitioner(encounter, 'ATND') ?? '',
      admittingDoctor: getEncounterPractitioner(encounter, 'ADM') ?? '',
      consultingDoctor: getEncounterPractitioner(encounter, 'CON') ?? '',
      diagnosisCodes: encounterDiagnosisCodes ?? '',
      dischargeDisposition: dischargeDisposition != '' && messageTriggerEventType === 'A03' ? dischargeDisposition : '',
      dischargeType: messageTriggerEventType === 'A03' && crossWalkDischargeDisposition != '' ? crossWalkDischargeDisposition ?? dischargeDisposition : '',
      sendingFacilityName: locationName ?? encounterLocationNpi ?? facilityName ?? facilityLocation ?? facilityLocationId ?? '',
      eventId: `${facilityName ?? facilityLocation ?? facilityLocationId ?? ''}-${messageControlId}`,
      eventType: encounterHeaderResource.eventCoding?.code ?? '',
      instructions: getEncounterInstructions(messageTriggerEventType, messageAdmissionType),
      eventCode: getEventCode(messageTriggerEventType),
      chronicConditions: [],
    }
  });
  return primeAdtData;
};

/**
 * Processes condition resources to extract diagnosis codes.
 * @param {Array} encounterConditionResource - Array of condition resources.
 * @returns {Array} - Processed diagnosis codes.
 */
function getEncounterDiagnosisCodes(encounterConditionResource) {

  return encounterConditionResource
    .filter((condition) => condition?.code?.coding)
    .map((condition) => ({
      code: condition.code.coding[0].code,
      description: condition.code.coding[0].display,
      chronicCondition: getExtensionValue(condition, '/ChronicCondition'),
    }));
}


/**
 * Retrieves the value of a specific extension from a resource.
 * @param {Object} resourceType - The resource containing the extension.
 * @param {string} urlPart - The URL part to match the extension.
 * @returns {string} - The value of the extension.
 */
function getExtensionValue(resourceType, urlPart) {
  return resourceType.extension?.find((e) => e.url?.includes(urlPart))?.valueString;
};

/**
 * Filters and retrieves resources of a specific type from the encounter bundle.
 * @param {Object} encounterBundle - The bundle containing encounter resources.
 * @param {string} resourceType - The type of resource to retrieve.
 * @returns {Array} - The filtered resources.
 */
function getEncounterResources(encounterBundle, resourceType) {
  return encounterBundle.entry?.filter((entry) => entry?.resourceType === resourceType).map((entry) => entry);
};

/**
 * Retrieves the event date from the message header resource.
 * @param {Object} messageHeader - The message header resource.
 * @returns {string} - The event date.
 */
function getEncounterEventDate(messageHeader) {
  const eventRecordTime = getExtensionValue(messageHeader, '/event-recorded-date-time');
  const eventOccurrenceTime = getExtensionValue(messageHeader, '/event-occurrence-date-time');
  const eventProcessTime = getExtensionValue(messageHeader, '/process-timestamp');
  return eventRecordTime ?? eventOccurrenceTime ?? eventProcessTime ?? '';
};

/**
 * Retrieves the event code based on the message trigger event type.
 * @param {string} messageTriggerEventType - The message trigger event type.
 * @returns {string} - The event code.
 */
function getEventCode(messageTriggerEventType) {
  switch (messageTriggerEventType) {
    case 'A01':
      return 'Admitted';
    case 'A03':
      return 'Discharged';
    case 'A04':
      return 'Registered';
    default:
      return '';
  }
};

/**
 * Constructs the event title based on the message trigger event type and location details.
 * @param {string} messageTriggerEventType - The message trigger event type.
 * @param {string} locationName - The location name.
 * @param {string} facilityName - The facility name.
 * @param {string} facilityLocation - The facility location.
 * @param {string} facilityLocationId - The facility location ID.
 * @returns {string} - The event title.
 */
function getEncounterEventTitle(messageTriggerEventType, locationName, facilityName, facilityLocation, facilityLocationId) {
  switch (messageTriggerEventType) {
    case 'A01':
      return 'Admitted to ' + (locationName ?? facilityName ?? facilityLocation ?? facilityLocationId);
    case 'A03':
      return 'Discharged from ' + (facilityName ?? facilityLocation ?? facilityLocationId);
    case 'A04':
      return 'Registered into ' + (facilityName ?? facilityLocation ?? facilityLocationId);
    default:
      return '';
  }
};

/**
 * Retrieves the patient ID from the patient resource.
 * @param {Object} patientResource - The patient resource.
 * @returns {string} - The patient ID.
 */
function getEncounterPatientId(patientResource) {
  const patientId = patientResource?.identifier?.find((e) => e.type?.coding?.[0]?.code === 'PN')?.value;
  const patientSsn = patientResource?.identifier?.find((e) => e.type?.coding?.[0]?.code === 'SS')?.value;
  return patientSsn ?? patientId ?? '';
};

/**
 * Retrieves the location details from the location resource.
 * @param {Object} locationResource - The location resource.
 * @returns {string} - The location details.
 */
function getEncounterLocation(locationResource) {
  const facilityLocation = getExtensionValue(locationResource, '/patient-location-facility-namespace-id');
  const facilityLocationId = getExtensionValue(locationResource, '/patient-location-facility-universal-id');
  return facilityLocation ?? facilityLocationId ?? '';
};

/**
 * Retrieves the practitioner details based on the role from the encounter resource.
 * @param {Object} practitionerResource 
 * @param {string} role - The role of the practitioner example Admitting/Consulting/Attending.
 * @returns {string} - The practitioner details.
 */
function getEncounterPractitioner(practitionerResource, role) {
  return practitionerResource?.participant?.find((e) => e.type[0]?.coding[0]?.code === role)?.individual?.display;
};

/**
 * Constructs the encounter instructions based on the event type and admission type.
 * @param {string} eventType - The event type.
 * @param {string} admissionType - The admission type.
 * @returns {string} - The encounter instructions.
 */
function getEncounterInstructions(eventType, admissionType) {
  switch (eventType) {
    case 'A01':
      switch (admissionType) {
        case 'IP Acute':
          return `<p>This message is to notify you that the member listed above has been admitted to the hospital per a data feed from the providing facility.
                     <br>This notification is being provided to:</p>
                      <ul>
                        <li>Facilitate coordination of care between yourself and the facility.</li>
                        <li>Alert you to the HEDIS recommended follow-up services post-discharge to home via telehealth or in person visit with PCP or their supporting NP or PA within 10 days of discharge and a medication reconciliation.</li>
                      </ul>`;
        case 'ED':
          return `<p>This message is to notify you that the member listed above has been admitted to the emergency room per a data feed from the providing facility.
                     <br>This notification is being provided to:</p>
                      <ul>
                        <li>Facilitate coordination of care between yourself and the ED facility.</li>
                        <li>Alert you to the HEDIS recommended post-ED follow-up service within 7 days of discharge to home. An additional ED discharge notification fax will follow along with guidance as to what services constitute a follow-up per HEDIS standards.</li>
                      </ul>`;
        default:
          return '';
      }
    case 'A03':
      switch (admissionType) {
        case 'ED':
          return `<p>This message is to notify you that the member listed above has been discharged from the emergency room per a data feed from the providing facility.
                       <br>This notification is being provided as an alert for a HEDIS-required post-ED follow-up service due within 7 days. The following services when documented and reported are common examples of what constitutes a follow-up per HEDIS standards:</p>
                        <ul>
                          <li>PCP, Specialist, or Specialty RN visit</li>
                          <li>PCP, Specialist, or Specialty RN telephonic visit</li>
                          <li>Transitional care management services</li>
                          <li>Case management or Complex case management </li>
                          <li>Outpatient, community mental health center or telehealth behavioral health visit</li>
                          <li>Intensive outpatient encounter or partial hospitalization</li>
                          <li>Telehealth, E-visit or virtual check-in</li>
                          <li>Observation</li>
                          <li>Substance use disorder service</li>
                        </ul>`;
        case 'IP Acute':
          return `<p>This message is to notify you that the member listed above has been discharged from the hospital per a data feed from the providing facility.
                     <br>This notification is being provided to:</p>
                      <ul>
                        <li>Alert you to the HEDIS recommended follow-up services post-discharge to home. The following services when documented and reported are examples of what constitutes a follow-up per HEDIS standards:</li>
                        <li>Acute inpatient: telehealth or in person with PCP or their supporting NP or PA follow up within 10 days and medication reconciliation* </li>
                        <li>Non-acute inpatient: medication reconciliation*</li>							
                      </ul>
                      <p>*Medication reconciliation guidelines: MD, NP, PA, RN or Clinical Pharmacist to reconcile discharge list with current medication list, must be documented in PCP chart</p>`;
        default:
          return '';
      }
    default:
      return '';
  }
};

export default {
  getPrimeEncounter,
}